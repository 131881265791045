<template>
  <v-app id="login" class="fondo">
    <template v-if="!authenticated">
      <v-layout align-center justify-center>
        <center>
          <v-flex>
            <v-card class="login-card" color="#fff">
              <div class="layout column align-center" id="titulo">
                <Typography variant="subtitle1" class="login-title" gutterBottom>
                  Panel de Administrador
                </Typography>
                <br />
              </div>              
              <v-card-text class="pt-2 pb-4 mt-4">
                <v-form v-model="validForm" lazy-validation @submit.prevent="lauchLogin">                  
                    <p class="mb-4 text-xs-center">Ingresa con tu Usuario y Contraseña</p>
                    <v-text-field
                      outlined
                      v-model="form.email"
                      :disabled="processingForm"
                      label="Usuario"
                      class="login-input"
                      :error="!!validationErrors.email"
                      :error-messages="validationErrors.email"
                      @keyup="
                        () => {
                          validationErrors.email = undefined;
                          delete validationErrors.email;
                        }
                      "
                    />
                    <br />
                    <v-text-field
                      outlined
                      v-model="form.password"
                      type="password"
                      label="Contraseña"
                      class="login-input"
                      :error="!!validationErrors.password"
                      :error-messages="validationErrors.password"
                      @keyup="
                        () => {
                          validationErrors.password = undefined;
                          delete validationErrors.password;
                        }
                      "
                    />                                   
                    <div class="text-xs-center">
                      <v-btn
                        type="submit"
                        color="#337ab7"
                        class="ma-2 white--text"
                        style="margin-top:35px !important;"
                        :loading="processingForm"
                      >
                        Iniciar sesión
                      </v-btn>
                    </div>                  
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </center>
      </v-layout>
    </template>
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {},
   metaInfo() {
    return { title: "Iniciar Sesión" };
  },
  data() {
    return {
      tryFacebookLogin: false,
      validForm: true,
      processingForm: false,
      form: {
        email: "",
        password: ""
      },
      validationErrors: {},
      remember: true,
      rules: {
        email: [
          v => !!v || "El correo electrónico es requerido",
          // eslint-disable-next-line no-useless-escape
          v =>
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "El correo electrónico debe ser válido"
        ],
        password: [v => !!v || "La contraseña es requerida"]
      }
    };
  },
  computed: {
    ...mapState({
      showModalLogin: state => state.auth.showModalLogin,
      primaryDrawer: state => state.layout.primaryDrawer
    }),

    ...mapGetters({
      authenticated: "auth/check"
    })
  },

  created() {
    if (this.authenticated) this.$router.push({ name: "carts" });
  },

  methods: {
    ...mapActions({
      login: "auth/login",
      replacePrimaryDrawer: "layout/replacePrimaryDrawer"
    }),

    lauchLogin() {
      this.processingForm = true;
      this.login({ params: this.form })
        .then(response => {
          // Save the token.
          // this.$store.dispatch("auth/saveToken", {
          //   token: response.data.token,
          //   remember: this.remember
          // });

          this.processingForm = false;
          this.replacePrimaryDrawerModel();          
          // Fetch the user.
          this.$store
            .dispatch("auth/fetchUser")
            .then(response => {
              this.processingForm = false;
              this.$router.push({ name: "carts" });
            })
            .catch(error => {
              this.processingForm = false;
            });
        })
        .catch(error => {
          this.processingForm = false;
          this.validationErrors = error.response.data.errors || {};
        });
    },

    replacePrimaryDrawerModel() {
      let primaryDrawer = this.primaryDrawer;
      primaryDrawer.model = true;

      this.replacePrimaryDrawer({ primaryDrawer });
    }
  }
};
</script>
<style scoped>
#login {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.theme--light.v-divider {
  border-color: transparent;
}
.fondo {
  background-color: #1c355e;
  background-size: cover;
}
.login-card {
  width: 340px;
  border-radius:18px !important;
  height: auto;
}
.login-title {
  color: white;
  font-size: 24px;
  font-family: "Montserrat", sans-serif !important;
  margin-top: 20px;
}
#titulo {
  height:80px;
  background:#182f84 ;
}
.login-image {
  margin: 20px auto 20px auto;
  max-width: 200px;
  border-radius: 14px !important;
}
.login-input {
  /* background-color: #a6a3a352; */
  height: 58px;
  margin-top: 0 !important;
  max-width: 90%;
  font-size: 1em;
  border-radius: 8px;
  transition-duration: 0.15s, 0.15s;
  padding:18px 15px;
}
.line {
  border-color: transparent;
}

@media only screen and (max-width: 400px) {
  .login-card {
    width: 280px;
  }
  .login-title {
    color: white;
    font-size: 16px;
    font-family: "Montserrat", sans-serif !important;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 401px) and (max-width: 960px) {
  .login-card {
    width: 400px;
  }
  .login-title {
    color: white;
    font-size: 16px;
    font-family: "Montserrat", sans-serif !important;
    margin-top: 20px;
  }
}
</style>
